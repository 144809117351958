import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ConnectTitleStrategyProvider extends TitleStrategy {
    constructor(
        private readonly title: Title
    ) {
        super();
    }

    public override updateTitle(routerState: RouterStateSnapshot): void {
        let title = this.buildTitle(routerState);

        if(null != title) {
            this.title.setTitle(`Connect - ${title}`);
        }
    }
}
