import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientService } from '@connect/secure/services/http-client.service';
import { BaseService } from '@connect/secure/services/base.service';
import { ClientModel } from '@connect/contact/models/client.model';

@Injectable()
export class ClientService extends BaseService {
    constructor(httpClientService: HttpClientService) {
        super(httpClientService);
    }

    public getClient = (clientId: string): Observable<ClientModel> => {
        return this.get<ClientModel>(`/api/clients/${clientId}`);
    }

    public saveClient = (model: ClientModel): Observable<ClientModel> => {
        return this.post<ClientModel>(`/api/clients`, model);
    }
}
