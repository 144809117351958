import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@connect/shared/components/base.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { TenantService } from '@connect/secure/services/tenant.service';
import { TenantModel } from '@connect/secure/models/tenant.model';

@Component({
    selector: 'secure-tenant-select-modal',
    templateUrl: './secure-tenant-select-modal.component.html'
})
export class SecureTenantSelectModalComponent extends BaseComponent implements OnInit {
    public tenants: Array<TenantModel> = [];
    public loading: boolean = false;

    constructor(
        private tenantService: TenantService,
        private dialog: DynamicDialogRef,
        data: DynamicDialogConfig<Array<TenantModel>>,
        messageService: MessageService
    ) {
        super(messageService);
        this.tenants = data.data || [];
    }

    public ngOnInit(): void {
        if (0 == this.tenants.length) {
            this.loading = true;
            this.tenantService.getTenants()
                .subscribe({
                    next: r => this.tenants = r,
                    complete: () => this.loading = false
                });
        }
    }

    public cancel = (): void => {
        this.dialog.close();
    }

    public selectTenant = (tenant: TenantModel): void => {
        this.dialog.close(tenant);
    }
}
