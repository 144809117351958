import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientService } from '@connect/secure/services/http-client.service';
import { BaseService } from '@connect/secure/services/base.service';
import { ContactSummaryViewModel } from '@connect/contact/models/contact-summary-view.model';
import { ContactDetailViewModel } from '@connect/contact/models/contact-detail-view.model';
import { PagedCollectionModel } from '@connect/shared/models/paged-collection.model';
import { ContactFilterModel } from '@connect/contact/models/contact-filter.model';
import { PaymentViewModel } from '@connect/finance/models/payment-view.model';
import { ContactModel } from '@connect/contact/models/contact.model';
import { CustomerProfileModel } from '@connect/public/models/customer-profile.model';
import { EventViewModel } from '@connect/calendar/models/event-view.model';

@Injectable()
export class ContactService extends BaseService {
    constructor(httpClientService: HttpClientService) {
        super(httpClientService);
    }

    public getContact = (contactId: string): Observable<ContactSummaryViewModel> => {
        return this.get<ContactSummaryViewModel>(`/api/contacts/${contactId}`);
    }

    public getContactInformation = (contactId: string): Observable<ContactModel> => {
        return this.get<ContactModel>(`/api/contacts/${contactId}/info`);
    }

    public getPagedContacts = (offset: number, pageSize: number): Observable<PagedCollectionModel<ContactSummaryViewModel>> => {
        let params = new HttpParams()
            .set('offset', offset)
            .set('page-size', pageSize);

        return this.get<PagedCollectionModel<ContactSummaryViewModel>>('/api/contacts', params);
    }
    
    public saveContact = (contact: ContactDetailViewModel): Observable<ContactDetailViewModel> => {
        return this.post<ContactDetailViewModel>('/api/contacts', contact);
    }

    public getFilteredContacts = (offset: number, pageSize: number, model: ContactFilterModel): Observable<PagedCollectionModel<ContactSummaryViewModel>> => {
        let params = new HttpParams();
        params = params.append('offset', offset);
        params = params.append('page-size', pageSize);

        if (null != model.firstName) {
            params = params.append('first-name', model.firstName);
        }

        if (null != model.lastName) {
            params = params.append('last-name', model.lastName);
        }

        if (null != model.phoneNumber) {
            params = params.append('phone-number', model.phoneNumber);
        }

        if (null != model.officeId) {
            params = params.append('office-id', model.officeId);
        }

        if (null != model.staffMemberUserId) {
            params = params.append('staff-member-user-id', model.staffMemberUserId);
        }

        if (null != model.timespanTypeId) {
            params = params.append('timespan-type-id', model.timespanTypeId);
        }

        if (null != model.contactStatusTypeId) {
            params = params.append('contact-status-type-id', model.contactStatusTypeId);
        }

        if (null != model.clientSourceTypeId) {
            params = params.append('client-source-type-id', model.clientSourceTypeId);
        }

        if (null != model.contactClientTypeId) {
            params = params.append('contact-client-type-id', model.contactClientTypeId);
        }

        if (null != model.contactPhaseTypeId) {
            params = params.append('contact-phase-type-id', model.contactPhaseTypeId);
        }

        if (null != model.createdByUserId) {
            params = params.append('created-by-user-id', model.createdByUserId);
        }

        if (null != model.purgedStatusTypeId) {
            params = params.append('purged-status-yype-id', model.purgedStatusTypeId);
        }

        return this.get<PagedCollectionModel<ContactSummaryViewModel>>('/api/contacts', params);
    }

    public searchContacts = (searchTerm: string): Observable<Array<ContactSummaryViewModel>> => {
        let params = new HttpParams();
        params = params.append('term', searchTerm);
        return this.get<Array<ContactSummaryViewModel>>('/api/contacts/search', params);
    }

    public getContactPayments = (contactId: string, offset: number, pageSize: number): Observable<PagedCollectionModel<PaymentViewModel>> => {
        let params = new HttpParams();
        params = params.append('offset', offset);
        params = params.append('page-size', pageSize);

        return this.get<PagedCollectionModel<PaymentViewModel>>(`/api/contacts/${contactId}/payments`, params);
    }

    public createProfile = (contactDetail: ContactDetailViewModel): Observable<CustomerProfileModel> => {
        return this.post<CustomerProfileModel>(`/api/contacts/profile`, contactDetail);
    }

    public getEvents = (contactId: string, offset: number, pageSize: number): Observable<PagedCollectionModel<EventViewModel>> => {
        let params = new HttpParams();
        params = params.append('offset', offset);
        params = params.append('page-size', pageSize);

        return this.get<PagedCollectionModel<EventViewModel>>(`/api/contacts/${contactId}/events`, params);
    }
}
