<div>
    <div class="w-100 border-bottom px-4 py-2">
        <ng-content select="[role=header]"></ng-content>
    </div>
    <div class="w-100 px-4 py-2">
        <ng-content select="[role=body]"></ng-content>
    </div>
    <div class="w-100 border-top px-4 py-2">
        <ng-content select="[role=footer]"></ng-content>
    </div>
</div>