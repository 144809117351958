import { Component, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { Message, MessageService } from 'primeng/api';

@Component({
    template: 'base-component'
})
export abstract class BaseComponent implements OnDestroy {
    protected componentDestroyed: Subject<void>;
    private readonly defaultMessage: Message = {
        severity: 'error',
        summary: 'Error',
        detail: 'An error has occurred'
    };

    constructor(protected messageService: MessageService) {
        this.componentDestroyed = new Subject<void>();
    }

    public ngOnDestroy(): void {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    protected handleError = (response: HttpErrorResponse): Observable<never> => {
        if ((null != response?.error?.errors) && (0 != response.error.errors.length)) {
            try {
                for (let e of response?.error?.errors) {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: e.errorMessage
                    });
                }
            } catch {
                this.messageService.add(this.defaultMessage);
            }
        } else {
            this.messageService.add(this.defaultMessage);
            console.error(response.error);
        }

        return throwError(() => response);
    }
}
