import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@connect/secure/services/base.service';
import { TenantModel } from '@connect/secure/models/tenant.model';
import { UserModel } from '@connect/secure/models/user.model';
import { HttpClientService } from '@connect/secure/services/http-client.service';
import { SharePointSiteModel } from '@connect/secure/models/sharepoint-site.model';
import { SharePointDriveModel } from '@connect/secure/models/sharepoint-drive.model';
import { TenantResourceModel } from '@connect/secure/models/tenant-resource.model';

@Injectable()
export class TenantService extends BaseService {
    constructor(httpClientService: HttpClientService) {
        super(httpClientService);
    }

    public getTenants = (): Observable<TenantModel[]> => {
        return this.get<TenantModel[]>('/api/tenants');
    }

    public getCurrentTenant = (): Observable<TenantModel> => {
        return this.get<TenantModel>('/api/tenants/current');
    }

    public getTenantUsers = (): Observable<UserModel[]> => {
        return this.get<UserModel[]>('/api/users');
    }

    public getTenantResources = (): Observable<TenantResourceModel> => {
        return this.get<TenantResourceModel>('/api/tenants/resources');
    }

    public getSites = (): Observable<SharePointSiteModel[]> => {
        return this.get<SharePointSiteModel[]>('/api/tenants/sites');
    }

    public getDrives = (siteId: string): Observable<SharePointDriveModel[]> => {
        return this.get<Array<SharePointSiteModel>>(`/api/tenants/sites/${siteId}/drives`);
    }

    public saveTenant = (model: TenantModel): Observable<void> => {
        return this.post<void>('/api/tenants', model);
    }

    public saveTenantConfiguration = (model: TenantResourceModel): Observable<void> => {
        return this.post<void>('/api/tenants/resources', model);
    }
}
