<modal-layout>
    <div role="header">Select Tenant</div>
    <div role="body">
        <loading-indicator [loading]="loading">
            <div class="row" *ngFor="let t of tenants">
                <div class="col">
                    <a href="javascript:void(0)" (click)="selectTenant(t)">{{ t.name }}</a>
                </div>
            </div>
        </loading-indicator>
    </div>
    <div role="footer" class="clearfix">
        <button class="btn btn-sm btn-outline-secondary float-end mx-1" (click)="cancel()">Cancel</button>
    </div>
</modal-layout>