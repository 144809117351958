import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { HttpClientService } from '@connect/secure/services/http-client.service';

export class BaseService {
    private readonly  apiHost: string;

    constructor(private clientWrapper: HttpClientService) {
    }

    protected delete = <T>(route: string): Observable<T> => {
        let uri = this.clientWrapper.constructAbsoluteUri(route);

        return this.clientWrapper.getBaseHeaders()
                   .pipe(mergeMap(h => this.clientWrapper.client.delete<T>(uri, { headers: h })));
    }

    protected get = <T>(route: string, queryParameters?: HttpParams): Observable<T> => {
        let uri = this.clientWrapper.constructAbsoluteUri(route);

        return this.clientWrapper.getBaseHeaders()
                   .pipe(mergeMap(h => this.clientWrapper.client.get<T>(uri, { headers: h, params: queryParameters })));
    }

    protected getAbsolute = <T>(route: string, queryParameters?: HttpParams): Observable<T> => {
        return this.clientWrapper.getBaseHeaders()
                   .pipe(mergeMap(h => this.clientWrapper.client.get<T>(route, { headers: h, params: queryParameters })));
    }

    protected post = <T>(route: string, body: any, headers?: HttpHeaders, queryParameters?: HttpParams): Observable<T> => {
        let uri = this.clientWrapper.constructAbsoluteUri(route);

        return this.clientWrapper.getBaseHeaders()
                   .pipe(
                       tap(h => h.set('Content-Type', 'application/json')),
                       tap(h => this.overlayHeaders(h, headers)),
                       mergeMap(h => this.clientWrapper.client.post<T>(uri, body, { headers: h, params: queryParameters }))
                   );
    }

    protected put = <T>(route: string, body: any, headers?: HttpHeaders, queryParameters?: HttpParams): Observable<T> => {
        let uri = this.clientWrapper.constructAbsoluteUri(route);

        return this.clientWrapper.getBaseHeaders()
                   .pipe(
                       tap(h => h.set('Content-Type', 'application/json')),
                       tap(h => this.overlayHeaders(h, headers)),
                       mergeMap(h => this.clientWrapper.client.put<T>(uri, body, { headers: h, params: queryParameters }))
                   );
    }

    private overlayHeaders = (target: HttpHeaders, source: HttpHeaders | undefined): HttpHeaders => {
        if (null == source) {
            return target;
        }

        for (let h in source.keys()) {
            let sourceValues = <string> source.get(h);
            target.set(h, sourceValues);
        }

        return target;
    }
}
