import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { WelcomeComponent } from '@connect/components/welcome/welcome.component';
import { ConnectTitleStrategyProvider } from '@connect/shared/providers/connect-title-strategy.provider';
import { ContactService } from './contact/services/contact.service';
import { LookupService } from './secure/services/lookup.service';
import { ClientService } from './contact/services/client.service';
import { HttpClientService } from './secure/services/http-client.service';

const routes: Routes = [
    { path: '', component: WelcomeComponent },
    { path: 'app', loadChildren: () => import('@connect/secure/secure-routing.module').then(m => m.SecureRoutingModule) },
    { path: 'public', loadChildren: () => import('@connect/public/public-routing.module').then(m => m.PublicRoutingModule )},
];

@NgModule({
    declarations: [
        WelcomeComponent
    ],
    exports: [
        RouterModule
    ],
    imports: [
        RouterModule.forRoot(routes),
        FormsModule
    ],
    providers: [
        { provide: TitleStrategy, useClass: ConnectTitleStrategyProvider },
        ContactService,
        LookupService,
        ClientService,
        HttpClientService
    ]
})
export class AppRoutingModule {
}
