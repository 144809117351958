import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, map, tap } from 'rxjs/operators';
import { ConfigModel } from '@connect/shared/models/config.model';
import { TenantModel } from '@connect/secure/models/tenant.model';
import { ModalService } from '@connect/shared/services/modal.service';
import { SecureTenantSelectModalComponent } from '@connect/secure/components/secure-tenant-select-modal/secure-tenant-select-modal.component';
import { ModalSize } from '@connect/shared/models/modal-size.enum';

const currentTenantStorageKey = 'connect.global.currentTenant';

@Injectable()
export class HttpClientService {
    private apiHost: string;

    constructor(
        public client: HttpClient,
        private modalService: ModalService,
        config: ConfigModel
    ) {
        let hostUri = config.api.host;

        if (true == hostUri.endsWith('/')) {
            hostUri = hostUri.substring(0, hostUri.length - 1);
        }

        this.apiHost = hostUri;
    }

    public get currentTenant(): TenantModel {
        let tenant = localStorage.getItem(currentTenantStorageKey);

        if (null == tenant) {
            return null as unknown as TenantModel;
        }

        return JSON.parse(tenant) as TenantModel;
    }

    public set currentTenant(tenant: TenantModel) {
        localStorage.setItem(currentTenantStorageKey, JSON.stringify(tenant));
    }

    public clearCurrentTenant = (): void => {
        localStorage.removeItem(currentTenantStorageKey);
    }

    public getBaseHeaders = (): Observable<HttpHeaders | never> => {
        if (null == this.currentTenant) {
            return this.getActiveTenants()
                       .pipe(
                           mergeMap(t => this.selectTenant(t)),
                           tap(t => this.currentTenant = t),
                           map(t => new HttpHeaders().set('X-TenantId', t.id))
                       );
        } else {
            return of(new HttpHeaders().set('X-TenantId', this.currentTenant.id))
        }
    }

    public constructAbsoluteUri = (route: string): string => {
        if (true == route.startsWith('/')) {
            route = route.substring(1);
        }

        return `${this.apiHost}/${route}`;
    }

    private getActiveTenants = (): Observable<TenantModel[]> => {
        let uri = this.constructAbsoluteUri('/api/tenants');

        return this.client.get<TenantModel[]>(uri);
    }

    private selectTenant = (tenants: TenantModel[]): Observable<TenantModel | never> => {
        if ((null == tenants) || (0 == tenants.length)) {
            return throwError(() => new Error('The user is not authorized to access any tenants'));
        } else if (1 == tenants.length) {
            return of(tenants[0]);
        } else {
            return this.modalService.open(SecureTenantSelectModalComponent, tenants, ModalSize.Small)
                        .pipe(mergeMap(r => {
                            if (null == r) {
                                return throwError(() => new Error('Unauthorized request'));
                            }

                            return of(r);
                        }));
        }
    }
}
