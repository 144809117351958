import { Injectable, Type } from '@angular/core'
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog'
import { ModalSize } from '@connect/shared/models/modal-size.enum';

@Injectable()
export class ModalService {
    constructor(
        private dialogService: DialogService
    ) {
    }

    public open = (modalComponentType: Type<any>, data?: any, modalSize: ModalSize = ModalSize.Medium): Observable<any> => {
        let config: DynamicDialogConfig = {
            contentStyle: {
                overflow: 'auto',
                padding: 0
            },
            data: data,
            showHeader: false,
            width: ''
        };

        if (ModalSize.Small == modalSize) {
            config.width = '25%';
        } else if (ModalSize.SmallMedium == modalSize) {
            config.width = '35%';
        } else if (ModalSize.Medium == modalSize) {
            config.width = '50%';
        } else if (ModalSize.Large == modalSize) {
            config.width = '75%';
        }
         else if (ModalSize.ExtraLarge == modalSize) {
            config.width = '90%';
        }

        let dialog = this.dialogService.open(modalComponentType, config);

        return dialog.onClose.pipe(first());
    }
}
