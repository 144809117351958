import { Component } from '@angular/core';

@Component({
    selector: 'modal-layout',
    templateUrl: './modal-layout.component.html'
})
export class ModalLayoutComponent {
    constructor() {
    }
}
