import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AppRoutingModule } from '@connect/app-routing.module';
import { AppComponent } from '@connect/app.component';
import { ContactService } from '@connect/contact/services/contact.service';
import { LookupService } from '@connect/secure/services/lookup.service';
import { ClientService } from '@connect/contact/services/client.service';
import { HttpClientService } from '@connect/secure/services/http-client.service';
import { ConfigModel } from '@connect/shared/models/config.model';

function loadConfig(http: HttpClient, config: ConfigModel): (() => Promise<boolean>) {
    return (): Promise<boolean> => {
        return new Promise<boolean>((resolve: (a: boolean) => void): void => {
            http.get<ConfigModel>('/assets/config.json')
                .subscribe({
                    next: (c: ConfigModel): void => {
                        Object.assign(config, c);
                        resolve(true);
                    },
                    error: e => resolve(false)
                });
        });
    };
}

@NgModule({
    bootstrap: [
        AppComponent
    ],
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ButtonModule,
        BrowserAnimationsModule,
        CommonModule,
        RadioButtonModule,
        FormsModule,
        HttpClientModule
    ],
    providers: [
        ContactService,
        LookupService,
        ClientService,
        HttpClientService,
        ConfigModel,
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            deps: [
                HttpClient,
                ConfigModel
            ],
            multi: true
        }
    ]
})
export class AppModule {
}
